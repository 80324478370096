.playerBox {
    background-color: black;
    color: white;
}

.playerDisplayBoxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.playerDisplayBox {
    width: 150px;
    padding-top: 5px;
    /*height: 150px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.displayTransparent {
  background-color: transparent;
  text-shadow: 0px 0px 2px black;
}

.ratingChange {
    position: absolute;
    font-weight: bold;
}

.positive {
    color: green;
}

.negative {
    color: red;
}

.startHidden {
    opacity: 0;
}

span.playerName {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeIn 2.0s ease-out forwards;
  display:inline-block;
}

span.playerCode {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeIn 2.0s ease-out forwards;
  animation-delay: 1.0s;
  display:inline-block;
}

@keyframes fadeIn{
  to{
    opacity: 1;
    transform: translateY(0);
  }
}